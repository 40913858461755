<template>
  <el-dialog
    id="EditRestrictPlanSettings"
    :title="title"
    :visible.sync="dialogShow"
    center
    modal
    top="5vh"
    :close-on-click-modal="false"
    width="496px"
    close-on-press-escape
    class="my-dialog abow_dialog"
    @opened="onShow"
    @keydown.enter.native="onCommit"
    destroy-on-close
  >
    <el-form ref="EditRestrictPlanSettingsForm" :model="model.planSettings" :rules="rules" @submit.native.prevent="onSubmit" v-loading="loading">
      <el-row v-if="loading" justify="center" align="middle" type="flex" style="height:270px">
        <el-empty :image-size="100" :image="require('../../../assets/icons/no_data.svg')"></el-empty>
      </el-row>
      <el-row v-else>
        <el-form-item prop="ruleId" :label="$t('Базовий план')">
          <el-select style="width:100%" v-model="model.planSettings.ruleId">
            <el-option v-for="item in model.rules" :key="item._id" :label="planName(item)" :value="item._id"> </el-option>
          </el-select>
        </el-form-item>
        <el-row justify="space-between" type="flex">
          <el-col :span="11">
            <el-form-item prop="planGRPercent" :label="$t('Модифікатор плану, %')">
              <el-input v-model="model.planSettings.planGRPercent" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item prop="planAlgType" :label="$t('Алгоритм розподілення')">
              <el-select style="width:100%" v-model="model.planSettings.planAlgType">
                <el-option v-for="item in selectOptions.planAlgType" :key="item.value" :label="$t(item.label)" :value="item.value"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-between" type="flex">
          <!--<el-col :span="11">
          <el-form-item prop="beginDate" :label="$t('Початок')">
            <el-date-picker type="date" format="dd.MM.yyyy" v-model="model.planSettings.beginDate" style="width:100%"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item prop="endDate" :label="$t('Кінець')">
            <el-date-picker type="date" format="dd.MM.yyyy" v-model="model.planSettings.endDate" style="width:100%"></el-date-picker>
          </el-form-item>
        </el-col>-->
          <!--<el-col :span="11" v-if="model.planSettings.planAlgType == 'shareByTurnover'">-->
          <el-col :span="11">
            <el-form-item prop="turnoverPeriodType" :label="$t('Період аналізу товарообігу')">
              <el-select style="width:100%" v-model="model.planSettings.turnoverPeriodType" :disabled="model.planSettings.planAlgType !== 'shareByTurnover'">
                <el-option v-for="item in selectOptions.turnoverPeriodType" :key="item.value" :label="$t(item.label)" :value="item.value"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item prop="saveAsDefault">
          <el-checkbox v-model="model.planSettings.saveAsDefault" :label="$t('Автоматично перераховувати план')"></el-checkbox>
        </el-form-item>
      </el-row>
      <el-button @click="onReset">{{ $t("Зкинути по замовчанню") }}</el-button>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button native-type="submit" type="success" @click="onCommit" class="apply-button">{{ $t("Застосувати") }}</el-button>
      <el-button type="text" @click="dialogShow = false" class="cancel-button">{{ $t("Відмінити") }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { bus } from "@/main";
import { _ } from "vue-underscore";

export default {
  name: "EditRestrictPlanSettings",
  components: {},
  data() {
    var that = this;
    var defaultValidateRule = { required: true, message: "Це поле є необхідним" };
    var digitalValueValidateRule = {
      validator: (rule, value, callback) => {
        if (value == undefined || value == "") {
          callback();
        } else if ((!that.isNumeric(value) && !that.isNumeric(value.split(",").join("."))) || value < 0) {
          callback(new Error("Поле має бути невід'ємним числом"));
        } else {
          callback();
        }
      },
    };
    var digitalValueNormalValidateRule = {
      validator: (rule, value, callback) => {
        if (value == undefined || value == "") {
          callback();
        } else if (!that.isNumeric(value) && !that.isNumeric(value.split(",").join("."))) {
          callback(new Error("Поле має бути числом"));
        } else {
          callback();
        }
      },
    };
    var checkBeginDate = (rule, value, callback) => {
      if (value && that.model.planSettings && that.model.planSettings.endDate) {
        if (new Date(value) > new Date(that.model.planSettings.endDate)) callback(new Error("Дата початку пізніше дати кінця"));
      }
      callback();
    };
    var checkEndDate = (rule, value, callback) => {
      if (value && that.model.planSettings && that.model.planSettings.beginDate) {
        if (new Date(value) < new Date(that.model.planSettings.beginDate)) callback(new Error("Дата кінця раніше дати початку"));
      }
      callback();
    };
    return {
      loading: false,
      dialogShow: false,
      title: "Розподілення плану",
      operation: null,
      rules: {
        ruleId: [defaultValidateRule],
        planGRPercent: [defaultValidateRule, digitalValueNormalValidateRule],
        planAlgType: [defaultValidateRule],
        beginDate: [
          {
            validator: checkBeginDate,
          },
        ],
        endDate: [
          {
            validator: checkEndDate,
          },
        ],
      },
      model: { planSettings: {}, rules: [] },
      initModel: {},
    };
  },

  methods: {
    onCommit() {
      this.$refs.EditRestrictPlanSettingsForm.validate((valid) => {
        if (valid) {
          this.$emit("commit", { model: this.prepareForCommit(this.model.planSettings) });
        } else {
          this.$message.error($tt("Перевірте введені дані!"));
        }
        return false;
      });
    },
    onReset() {
      this.$confirm($tt("Ви дійсно бажаєте зкинути налаштування розподілення плану?"), $tt("Увага"), {
        confirmButtonText: $tt("Так"),
        cancelButtonText: $tt("Ні"),
        type: "warning",
      })
        .then(() => {
          this.loading = true; //this.$loading({ target: "#EditUserProfileDialog>div", text: this.$t("Будь ласка, зачекайте...") });
          this.$store
            .dispatch("restricts/updatePlanSettings", {
              contractId: this.$store.getters["contracts/contract"]()._id,
              restrictId: this.$store.getters["restricts/restrict"]()._id,
              data: {},
            })
            .finally(() => {
              this.loading = false;
              //loading && loading.close();
            })
            .then(() => {
              this.dialogShow = false;
            })
            .catch((err) => {
              globalErrorMessage($tt("Помилка збереження!"), err);
            });
        })
        .catch(() => {});
    },
    onShow() {
      this.$refs.EditRestrictPlanSettingsForm.resetFields();
      this.model = _.extend({}, this.initModel);
      this.$nextTick(() => (this.loading = false));
    },
    prepareForCommit(data) {
      data.planGRPercent = data.planGRPercent && typeof data.planGRPercent == "string" ? data.planGRPercent.split(",").join(".") : data.planGRPercent;
      return data;
    },
    nextFocus(next) {
      if (next == "commit") {
        this.onCommit();
      } else this.$refs[next].focus();
    },
    planName(item) {
      if (item && item.notes) return `План: ${item.plan}, бонус: ${item.bonus}  (${item.name})`;
      else return `План: ${item.plan || item.plan == 0 ? item.plan : 0}, бонус: ${item.bonus || item.bonus == 0 ? item.bonus : 0}`;
    },
  },
  created() {
    bus.$on("forms.rules.plan.edit.show", (data) => {
      this.loading = true;
      this.title = (data && data.title) || this.title;
      this.operation = (data && data.operation) || "create";
      this.initModel = _.extend({ planSettings: { planGRPercent: 0 }, rules: [] }, data && data.initModel ? data.initModel : {});
      this.dialogShow = true;
    });
    bus.$on("forms.rules.plan.edit.hide", () => {
      this.dialogShow = false;
    });
  },
  computed: {
    selectOptions() {
      return this.$store.getters["main/selectionOptions"].restricts;
    },
  },
};
</script>
<style lang="scss">
#EditRestrictPlanSettings {
  .el-dialog {
    max-height: 480px !important;
  }
}
</style>
